/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/*  App Element Geometry  */
#configurator {
  margin: 10px;
  /* border: 1px solid #aaaaaa; */
  padding: 10px;
}

#configurator #header {
  font-family: 'futura-pt-bold', 'Jost';
  font-weight: 700;
  font-size: 36pt;
  color:#333333;
  /* border: 1px solid #aaaaaa; */
  /* display:none; */
}
@media screen and (max-width: 700px) {
  #configurator #header  {
    font-size: 28pt;
  }
}

#configurator #primary-container #feedback-container {
  width: 60%;
  display: inline-block;
  /* border: 1px solid #aaaaaa; */
  vertical-align: top;
}
@media screen and (max-width: 700px) {
  #configurator #primary-container #feedback-container {
    width: 100%;
  }
}

#configurator #primary-container #feedback-container #image img {
  width:100%;
}
#configurator #primary-container #feedback-container #pricing #base_pricing,
#configurator #primary-container #feedback-container #pricing #config_pricing {
  width:49%;
  display:inline-block;
  font-family:'futura-pt-bold', 'Jost';
  font-weight: 500;
  font-size: 16pt;
  color:#888888;
}
#configurator #primary-container #feedback-container #pricing #config_pricing {
  text-align:right;
  visibility: hidden;
}
#configurator #primary-container #feedback-container #pricing #base_pricing #base_price {
  color:#333333;
  font-weight: 500;
}
#configurator #primary-container #feedback-container #pricing #config_pricing #config_price {
  color:#397bab;
  font-weight: 700;
}
@media screen and (max-width: 700px) {
  #configurator #primary-container #feedback-container #pricing #base_pricing,
  #configurator #primary-container #feedback-container #pricing #config_pricing {
    width:100%;
  }
  #configurator #primary-container #feedback-container #pricing #config_pricing {
    display:none;
  }

}


#configurator #primary-container .option-container #personal {
  /* width: 48%; */
  display:inline-block;
  vertical-align: top;
}
#configurator #primary-container .option-container #personal input, #configurator #primary-container .option-container #personal textarea {
  border:1px solid #333333;
  padding:3px;
  border-radius: 4px;
  margin: 0px;
  font-family: 'Libre Baskerville', 'serif';
  font-size: 14pt;
  width: 97%;
}

#configurator #primary-container .option-container #personal .inputTitle {
  width:100%;
  display: inline-block;
  font-family: 'futura-pt-bold', 'Jost';
  font-weight: 400;
  font-size: 8pt;
  text-transform: uppercase;
  color:#777777;
}

#configurator #primary-container .option-container #personal .inputTitle .required {
  color:#ff0000;
  font-weight: 700;
}

#configurator #primary-container .option-container #personal .halfwide {
  width: 50%;
  display: inline-block;
}
#configurator #primary-container .option-container #personal .halfwide input {
  width: 95%;
}
#configurator #primary-container .option-container #personal .halfwide input.state {
  width: 25%;
  margin-right:5px;
}
#configurator #primary-container .option-container #personal .halfwide input.zip {
  width: 60%;
}
#configurator #primary-container .option-container #personal .fullwide {
  width: 100%;
  display: inline-block;
}
#configurator #primary-container .option-container #personal .fullwide input {
  width: 97.5%;
}

#configurator #primary-container #feedback-container #my-boat {
  width:95%;
  display: inline-block;
  vertical-align: top;
}
#configurator #primary-container #feedback-container #my-boat {
  /* width:45%; */
  display: inline-block;
  /* display:none; */
  vertical-align: top;
  padding-left: 5px;
}
#configurator #primary-container .option-container #personal h1,
#configurator #primary-container #feedback-container #my-boat .selected-option-container h1 {
  width:100%;
  display: inline-block;
  font-family: 'futura-pt-bold', 'Jost';
  font-weight: 700;
  font-size: 16pt;
  text-transform: uppercase;
  color:#333333;
  margin:0px;
}
#configurator #primary-container .option-container #personal p,
#configurator #primary-container #feedback-container #my-boat .selected-option-container p {
  list-style: none;
  display: inline-block;
  font-family: 'aktiv-grotesk', 'Libre Baskerville', 'serif';
  font-weight: 700;
  font-size: 8pt;
  color:#333333;
  width: 100%;
  padding: 0 0;
  line-height: 1.2em;
}
#configurator #primary-container #feedback-container #my-boat .selected_feature_category h2 {
  width:100%;
  display: inline-block;
  font-family: 'futura-pt-bold', 'Jost';
  font-weight: 400;
  font-size: 12pt;
  text-transform: uppercase;
  background-color:#333333;
  color:#cccccc;
  padding:1px;
  padding-left:3px;
  margin:0px;
}
#configurator #primary-container #feedback-container #my-boat .selected_feature_category ul {
  margin:0;
  padding: 0 0 0 .5em;
}
#configurator #primary-container #feedback-container #my-boat .selected_feature_category ul li {
  list-style: none;
  display: inline-block;
  font-family: 'aktiv-grotesk', 'Libre Baskerville', 'serif';
  font-weight: 700;
  font-size: 8pt;
  color:#333333;
  width: 100%;
  padding: 0 0;
  line-height: 0.8em;
}
#configurator #primary-container #feedback-container #my-boat .selected_feature_category ul li .option_text {
  display:inline-block;
  width:75%;
}
#configurator #primary-container #feedback-container #my-boat .selected_feature_category ul li .price_container {
  width:20%;
  display:inline-block;
  text-align:right;
  visibility: hidden;
}

@media screen and (max-width: 700px) {
  #configurator #primary-container #feedback-container #my-boat {
    display:none;
  }
}
#configurator #primary-container .option-container #submitMessage {
  font-family: 'aktiv-grotesk', 'Libre Baskerville', 'serif';
  font-weight: 700;
  font-size: 8pt;
  color:#ff0000;
}

#configurator #primary-container .option-container button {
  background-color: #ffffff; 
  border: 2px solid #333333;
  color: #333333;
  font-family: 'futura-pt-bold', 'Jost';
  font-weight: 700;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top:10px;
  border-radius: 7px;
}
#configurator #primary-container .option-container #submit2 {
  float:right;
}

#configurator #primary-container .option-container {
  width: 39%;
  display:inline-block;
  padding-left:5px;
  border-left:2px solid #333333;
}
@media screen and (max-width: 700px) {
  #configurator #primary-container .option-container {
    width: 100%;
    border-left:0;
  }
}

#configurator #primary-container .option-container .feature_category {
  /* border: 1px solid #aaaaaa; */
}
#configurator #primary-container .option-container .feature_category h2 {
  font-family:'futura-pt-bold', 'Jost';
  font-weight: 700;
  font-size: 14pt;
  color:#cccccc;
  background-color: #333333;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
  padding-left:.4em;
  margin:0px;
}
#configurator #primary-container .option-container .feature_category h2 .expandGroup {
  display:inline-block;
  float:right;
  padding-right: .5em;
}
#configurator #primary-container .option-container .feature_category .feature_item h3 {
  font-family:'futura-pt-bold', 'Jost';
  font-weight: 700;
  font-size: 12pt;
  color:#333333;
  padding-left:.4em;
  margin:0px;
}
#configurator #primary-container .option-container .feature_category .feature_item ul {
  margin:0;
  padding: 0 0 10px .5em;
}
#configurator #primary-container .option-container .feature_category .feature_item li {
  list-style: none;
  display: inline-block;
  font-family: 'aktiv-grotesk', 'Libre Baskerville', 'serif';
  font-weight: 700;
  font-size: 10pt;
  color:#333333;
  width: 100%;
  padding: 3px 0;
  border-bottom:1px solid #cccccc;
}
#configurator #primary-container .option-container .feature_category .feature_item li.selected {
  background-color: #cddfcd;
}

#configurator #primary-container .option-container .feature_category .feature_item li .option_text {
  display:inline-block;
  width:80%;
}
@media screen and (max-width: 700px) {
  #configurator #primary-container .option-container .feature_category .feature_item li .option_text {
    width: 60%;
  }
}

#configurator #primary-container .option-container .feature_category .feature_item li .includedInBasePrice {
  display:none;
}

#configurator #primary-container .option-container .feature_category .feature_item li .price_change_container {
  width:15%;
  display:inline-block;
  text-align:right;
}
@media screen and (max-width: 700px) {
  #configurator #primary-container .option-container .feature_category .feature_item li .price_change_container {
    width: 30%;
  }
}
#configurator #primary-container .option-container .feature_category .feature_item li .price_change_container .price_change_text {
  display:inline-block;
  border:1px solid #9ad3fc;
  border-radius: 8px;
  padding:1px 4px;
  background-color: #d4edff;
  font-size:8pt;
  visibility:hidden;
}
#configurator #primary-container .option-container .feature_category .feature_item li .descriptionText {
  font-family:'futura-pt-bold', 'Jost';
  font-weight: 300;
  font-size: 9pt;
  color:#444444;
  padding-left:1.4em;
  width:90%;
  display:inline-block;
}

#configurator #primary-container .option-container .feature_category .feature_item li input.image {
   vertical-align:top;
   margin-top:16px;
   margin-right: 7px;
}
#configurator #primary-container .option-container .feature_category .feature_item li .image img {
  display:inline-block;
  width:40px;
  height:40px;
}
#configurator #primary-container .option-container .feature_category .feature_item li.image .description {
  display:inline-block;
  vertical-align: top;
  padding-left: 7px;
  margin-top:16px;
}
#configurator #primary-container .option-container .feature_category .feature_item li.image .price_change_container {
  vertical-align: top;
  margin-top:16px;
}
#image {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_base.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position:relative;
  display: inline-block;
}
#image .fill {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.0);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
}

/* 18 foot */
/* 12 degree hull */
#image .fill#hull_color #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_12degree-white2.png");
}
#image .fill#hull_color.abalone #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_12degree-abalone2.png");
}
#image .fill#hull_color.courtesan #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_12degree-courtesan2.png");
}
#image .fill#hull_color.gray #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_12degree-gray2.png");
}
#image .fill#hull_color.lagoon #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_12degree-lagoon2.png");
}
#image .fill#hull_color.tortuga #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_12degree-tortuga2.png");
}
#image .fill#hull_color.white #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_12degree-white2.png");
}
/* 2 degree hull */
#image .fill#hull_color #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_2degree-white2.png");
}
#image .fill#hull_color.abalone #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_2degree-abalone2.png");
}
#image .fill#hull_color.courtesan #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_2degree-courtesan2.png");
}
#image .fill#hull_color.gray #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_2degree-gray2.png");
}
#image .fill#hull_color.lagoon #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_2degree-lagoon2.png");
}
#image .fill#hull_color.tortuga #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_2degree-tortuga2.png");
}
#image .fill#hull_color.white #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_2degree-white2.png");
}
/* console  */
#image .fill#console_color #console {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_standard-none2.png");
}
#image .fill#console_color #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_standard-none2.png");
}
#image .fill#console_color.abalone #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_standard-abalone2.png");
}
#image .fill#console_color.courtesan #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_standard-courtesan2.png");
}
#image .fill#console_color.gray #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_standard-gray2.png");
}
#image .fill#console_color.lagoon #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_standard-lagoon2.png");
}
#image .fill#console_color.tortuga #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_standard-tortuga2.png");
}
#image .fill#console_color.white #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_standard-white2.png");
}
#image .fill#console_color.abalone #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_small-abalone2.png");
}
#image .fill#console_color.courtesan #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_small-courtesan2.png");
}
#image .fill#console_color.gray #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_small-gray2.png");
}
#image .fill#console_color.lagoon #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_small-lagoon2.png");
}
#image .fill#console_color.tortuga #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_small-tortuga2.png");
}
#image .fill#console_color.white #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_small-white2.png");
}
#image .fill#console_color.abalone #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_side-abalone2.png");
}
#image .fill#console_color.courtesan #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_side-courtesan2.png");
}
#image .fill#console_color.gray #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_side-gray2.png");
}
#image .fill#console_color.lagoon #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_side-lagoon2.png");
}
#image .fill#console_color.tortuga #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_side-tortuga2.png");
}
#image .fill#console_color.white #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_side-white2.png");
}
#image .fill#console_color.abalone #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_medium-abalone2.png");
}
#image .fill#console_color.courtesan #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_medium-courtesan2.png");
}
#image .fill#console_color.gray #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_medium-gray2.png");
}
#image .fill#console_color.lagoon #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_medium-lagoon2.png");
}
#image .fill#console_color.tortuga #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_medium-tortuga2.png");
}
#image .fill#console_color.white #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_medium-white2.png");
}
/* nonskid */
#image .fill#nonskid {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_nonskid-none2.png");
}
#image .fill#nonskid.abalone {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_nonskid-abalone2.png");
}
#image .fill#nonskid.courtesan {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_nonskid-courtesan2.png");
}
#image .fill#nonskid.gray {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_nonskid-gray2.png");
}
#image .fill#nonskid.lagoon {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_nonskid-lagoon2.png");
}
#image .fill#nonskid.tortuga {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_nonskid-tortuga2.png");
}
#image .fill#nonskid.white {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_nonskid-white2.png");
}
/* hatches */
#image .fill#deck_color #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_3hatch-none2.png");
}
#image .fill#deck_color.abalone #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_3hatch-abalone2.png");
}
#image .fill#deck_color.courtesan #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_3hatch-courtesan2.png");
}
#image .fill#deck_color.gray #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_3hatch-gray2.png");
}
#image .fill#deck_color.lagoon #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_3hatch-lagoon2.png");
}
#image .fill#deck_color.tortuga #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_3hatch-tortuga2.png");
}
#image .fill#deck_color.white #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_5hatch-white2.png");
}
#image .fill#deck_color #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_5hatch-none2.png");
}
#image .fill#deck_color.abalone #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_5hatch-abalone2.png");
}
#image .fill#deck_color.courtesan #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_5hatch-courtesan2.png");
}
#image .fill#deck_color.gray #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_5hatch-gray2.png");
}
#image .fill#deck_color.lagoon #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_5hatch-lagoon2.png");
}
#image .fill#deck_color.tortuga #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_5hatch-tortuga2.png");
}
#image .fill#deck_color.white #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_5hatch-white2.png");
}
/* top liner colors */
#image .fill#liner.abalone {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_liner-abalone2.png");
}
#image .fill#liner.courtesan {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_liner-courtesan2.png");
}
#image .fill#liner.gray {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_liner-gray2.png");
}
#image .fill#liner.lagoon {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_liner-lagoon2.png");
}
#image .fill#liner.tortuga {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_liner-tortuga2.png");
}
#image .fill#liner.white {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_liner-white2.png");
}
/* engines */
#image .fill#engine.mercury60 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_mercury602.png");
}
#image .fill#engine.mercury115 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_mercury115proxs2.png");
}
#image .fill#engine.tohatsu60 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_tohatsu602.png");
}
#image .fill#engine.yamaha30 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_yamaha302.png");
}
#image .fill#engine.yamaha60 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_yamaha602.png");
}
#image .fill#engine.yamaha70 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_yamaha602.png");
}
#image .fill#engine.yamaha90 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_yamaha90115sho2.png");
}
#image .fill#engine.yamaha115 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/18-foot/chi18_yamaha90115sho2.png");
}

/* 21 foot Images */
/* 12 degree hull */
#image.twentyone .fill#hull_color #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_12degree-white2.png");
}
#image.twentyone .fill#hull_color.abalone #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_12degree-abalone2.png");
}
#image.twentyone .fill#hull_color.courtesan #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_12degree-courtesan2.png");
}
#image.twentyone .fill#hull_color.gray #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_12degree-gray2.png");
}
#image.twentyone .fill#hull_color.lagoon #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_12degree-lagoon2.png");
}
#image.twentyone .fill#hull_color.tortuga #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_12degree-tortuga2.png");
}
#image.twentyone .fill#hull_color.white #degrees.degree12 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_12degree-white2.png");
}
/* 2 degree hull */
#image.twentyone .fill#hull_color #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_2degree-white2.png");
}
#image.twentyone .fill#hull_color.abalone #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_2degree-abalone2.png");
}
#image.twentyone .fill#hull_color.courtesan #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_2degree-courtesan2.png");
}
#image.twentyone .fill#hull_color.gray #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_2degree-gray2.png");
}
#image.twentyone .fill#hull_color.lagoon #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_2degree-lagoon2.png");
}
#image.twentyone .fill#hull_color.tortuga #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_2degree-tortuga2.png");
}
#image.twentyone .fill#hull_color.white #degrees.degree2 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_2degree-white2.png");
}
/* console  */
#image.twentyone .fill#console_color #console {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_standard-none2.png");
}
#image.twentyone .fill#console_color #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_standard-none2.png");
}
#image.twentyone .fill#console_color.abalone #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_standard-abalone2.png");
}
#image.twentyone .fill#console_color.courtesan #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_standard-courtesan2.png");
}
#image.twentyone .fill#console_color.gray #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_standard-gray2.png");
}
#image.twentyone .fill#console_color.lagoon #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_standard-lagoon2.png");
}
#image.twentyone .fill#console_color.tortuga #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_standard-tortuga2.png");
}
#image.twentyone .fill#console_color.white #console.standard {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_standard-white2.png");
}
#image.twentyone .fill#console_color.abalone #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_small-abalone2.png");
}
#image.twentyone .fill#console_color.courtesan #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_small-courtesan2.png");
}
#image.twentyone .fill#console_color.gray #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_small-gray2.png");
}
#image.twentyone .fill#console_color.lagoon #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_small-lagoon2.png");
}
#image.twentyone .fill#console_color.tortuga #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_small-tortuga2.png");
}
#image.twentyone .fill#console_color.white #console.small {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_small-white2.png");
}
#image.twentyone .fill#console_color.abalone #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_side-abalone2.png");
}
#image.twentyone .fill#console_color.courtesan #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_side-courtesan2.png");
}
#image.twentyone .fill#console_color.gray #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_side-gray2.png");
}
#image.twentyone .fill#console_color.lagoon #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_side-lagoon2.png");
}
#image.twentyone .fill#console_color.tortuga #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_side-tortuga2.png");
}
#image.twentyone .fill#console_color.white #console.side {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_side-white2.png");
}
#image.twentyone .fill#console_color.abalone #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_medium-abalone2.png");
}
#image.twentyone .fill#console_color.courtesan #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_medium-courtesan2.png");
}
#image.twentyone .fill#console_color.gray #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_medium-gray2.png");
}
#image.twentyone .fill#console_color.lagoon #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_medium-lagoon2.png");
}
#image.twentyone .fill#console_color.tortuga #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_medium-tortuga2.png");
}
#image.twentyone .fill#console_color.white #console.medium {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_medium-white2.png");
}
/* nonskid */
#image.twentyone .fill#nonskid {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_nonskid-none2.png");
}
#image.twentyone .fill#nonskid.abalone {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_nonskid-abalone2.png");
}
#image.twentyone .fill#nonskid.courtesan {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_nonskid-courtesan2.png");
}
#image.twentyone .fill#nonskid.gray {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_nonskid-gray2.png");
}
#image.twentyone .fill#nonskid.lagoon {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_nonskid-lagoon2.png");
}
#image.twentyone .fill#nonskid.tortuga {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_nonskid-tortuga2.png");
}
#image.twentyone .fill#nonskid.white {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_nonskid-white2.png");
}
/* hatches */
#image.twentyone .fill#deck_color #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_3hatch-none2.png");
}
#image.twentyone .fill#deck_color.abalone #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_3hatch-abalone2.png");
}
#image.twentyone .fill#deck_color.courtesan #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_3hatch-courtesan2.png");
}
#image.twentyone .fill#deck_color.gray #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_3hatch-gray2.png");
}
#image.twentyone .fill#deck_color.lagoon #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_3hatch-lagoon2.png");
}
#image.twentyone .fill#deck_color.tortuga #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_3hatch-tortuga2.png");
}
#image.twentyone .fill#deck_color.white #hatch.hatch3 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_5hatch-white2.png");
}
#image.twentyone .fill#deck_color #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_5hatch-none2.png");
}
#image.twentyone .fill#deck_color.abalone #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_5hatch-abalone2.png");
}
#image.twentyone .fill#deck_color.courtesan #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_5hatch-courtesan2.png");
}
#image.twentyone .fill#deck_color.gray #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_5hatch-gray2.png");
}
#image.twentyone .fill#deck_color.lagoon #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_5hatch-lagoon2.png");
}
#image.twentyone .fill#deck_color.tortuga #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_5hatch-tortuga2.png");
}
#image.twentyone .fill#deck_color.white #hatch.hatch5 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_5hatch-white2.png");
}
/* top liner colors */
#image.twentyone .fill#liner.abalone {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_liner-abalone2.png");
}
#image.twentyone .fill#liner.courtesan {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_liner-courtesan2.png");
}
#image.twentyone .fill#liner.gray {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_liner-gray2.png");
}
#image.twentyone .fill#liner.lagoon {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_liner-lagoon2.png");
}
#image.twentyone .fill#liner.tortuga {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_liner-tortuga2.png");
}
#image.twentyone .fill#liner.white {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_liner-white2.png");
}
/* engines */
#image.twentyone .fill#engine.mercury60 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_mercury602.png");
}
#image.twentyone .fill#engine.mercury115 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_mercury115proxs2.png");
}
#image.twentyone .fill#engine.tohatsu60 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_tohatsu602.png");
}
#image.twentyone .fill#engine.yamaha30 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_yamaha302.png");
}
#image.twentyone .fill#engine.yamaha60 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_yamaha602.png");
}
#image.twentyone .fill#engine.yamaha90 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_yamaha90115sho2.png");
}
#image.twentyone .fill#engine.yamaha115 {
  background-image: url("https://chittum-config.nyc3.cdn.digitaloceanspaces.com/configurator/21-foot/chi21_yamaha90115sho2.png");
}